let elements = document.getElementsByClassName("play-btn");

for(let i = 0; i < elements.length; i++) {
    elements[i].onclick = function (e) {
        e.preventDefault();
        const videoID = this.dataset.videoId;
        const videoIframe = document.getElementById(videoID+'-video');
        videoIframe.src = "https://www.youtube.com/embed/"+videoID+"?autoplay=1";
        videoIframe.classList.remove("d-none");
    }
}
