let googleMap = document.getElementById('googleMap');

if (typeof(googleMap) != 'undefined' && googleMap != null) {
    map = new google.maps.Map(document.getElementById("googleMap"), {
        center: {lat: parseFloat(googleMap.dataset.lat), lng: parseFloat(googleMap.dataset.lng)},
        zoom: parseInt(googleMap.dataset.zoom)
    });

    marker = new google.maps.Marker({
        map: map,
        position: new google.maps.LatLng(googleMap.dataset.lat, googleMap.dataset.lng),
        icon: window.location.origin + "/images/map-pin-blue.png"
    });
}
