const notificationModalElm = document.getElementById('notificationModal');

if (notificationModalElm) {
    let notificationModal = new bootstrap.Modal(notificationModalElm);
    let showCheck = localStorage.getItem("closed"+notificationModalElm.dataset.name+'Modal');

    if (!showCheck) {
        notificationModal.show();
    }

    notificationModalElm.addEventListener('shown.bs.modal', function (event) {
        document.body.style.overflowY = 'auto';
    });

    notificationModalElm.addEventListener('hidden.bs.modal', function (event) {
        localStorage.setItem("closed"+notificationModalElm.dataset.name+'Modal', "1");
    });
}
