window.axios = require('axios');

window.addEventListener('widgetsLoaded', function() {
    window.bootstrap = require('bootstrap');
    require('slick-carousel');

    require('./components/sliders');
    require('./components/full-width-video');
    require('./components/quick-search');
    require('./components/map');
    require('./components/google-places-search');

    require('./pages/news');
    require('./pages/contact');

    // Scroll to page top
    document.getElementById("scrollTop").addEventListener("click", function(e) {
        e.preventDefault();
        window.scrollTo(0, 0);
    });

    // Cookie consent
    require('./components/quantcast-cookie-consent-manager');

    // Captcha
    require('./components/recaptcha');

    // Notification Modal
    require('./components/notification');
});
