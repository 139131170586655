import $ from 'jquery';

const hasSliders = document.querySelectorAll(".testimonials-slider, .image-slider");

if (hasSliders.length > 0) {
    initaliseSlider();
}

function initaliseSlider() {
    const testimonialSlider = $('.testimonials-slider').slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    });

    const imageSlider = $('.image-slider').slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        centerMode: true,
        variableWidth: true
    });

    $('.slider-prev').click(function (e) {
        e.preventDefault();
        testimonialSlider.slick('slickPrev');
        imageSlider.slick('slickPrev');
    });

    $('.slider-next').click(function (e) {
        e.preventDefault();
        testimonialSlider.slick('slickNext');
        imageSlider.slick('slickNext');
    });
}
